export default Object.freeze({
    SIZE_xs: 0,
    SIZE_sm: 600,
    SIZE_md: 1024,
    SIZE_lg: 1440,
    LIST_COUNT_PER_PAGE: 20,
    DEFAULT_PAGE_NUM: 5,
    REQUIRE_2FA: '2fa',
    SERVER_CODE_2FA: '112',
    LOGINED: 'logined',
    S3_STATIC: 'news.infostock.co.kr',
    AD_MENU: [
        {
            label: '광고',
            resource: false,
            icon: 'dashboard',
            open: true,
            path: '/marketing/advertising.htm',
        },
    ],
    PR_MENU: [
        {
            label: '기사관리',
            resource: false,
            icon: 'dashboard',
            open: true,
            path: '/irnews/manage.htm',
        },

    ],
    MK_MENU: [
        {
            label: '주식신문',
            resource: false,
            icon: 'history_edu',
            open: true,
            path: '/newspaper',
            sub: [
                { label: '미리보기', route: 'preview.htm', paper: true },
            ]
        },
        {
            label: '마케팅',
            resource: false,
            icon: 'dashboard',
            open: true,
            path: '/marketing',
            sub: [
                { label: '광고', route: 'advertising.htm' },
                { label: '광고확인', route: 'advertisingCnfirm.htm' },
                // <!-- 20230920 광고매크로 -->
                // { label: "광고전송", route: "advertisingmacro.htm" },
            ]
        },
        {
            label: 'IR',
            resource: false,
            icon: 'dashboard',
            open: true,
            path: '/irnews',
            sub: [
                { label: 'IR 속보', route: 'flash.htm' },
                { label: 'IR/제휴언론사 기사관리', route: 'manage.htm' },
                { label: 'IR 인사이트', route: 'irinsight.htm' },
            ]
        },
        {
            label: '데이터',
            resource: false,
            icon: 'topic',
            open: true,
            path: '/data',
            sub: [
                { label: '통계', route: 'advertising.htm' },
                { label: '전자공시', route: 'discl.htm', resource: false },
                // { label: '과거 실적/재무 조회', route: 'advertising.htm' },
            ]
        },
        {
            label: '관리',
            resource: false,
            icon: 'manage',
            open: true,
            path: '/manage',
            sub: [
                { label: '공지사항', route: 'notice.htm' },
                { label: '사내게시판', route: 'inboard.htm' },
                { label: '업무 관리', route: 'workmanager.htm' },
                { label: '관리자 관리', route: 'staff.htm' },
                { label: '증권사 관리', route: 'stockcompany.htm' },
                { label: '회원관리', route: 'member.htm' },

            ]
        },
        {
            label: '전송관리 시스템',
            resource: false,
            icon: 'trnsmis',
            open: true,
            path: '/trnsmis',
            sub: [
                { label: '인포스탁 전송관리', route: 'infostock.htm' },
                { label: '인포스탁 재전송 ', route: 'resend.htm' },
                { label: '제휴언론사 전송관리', route: 'coprtnOxpr.htm' },
                { label: '광고고객사 전송관리', route: 'advrtsCtmmny.htm' },
                { label: '줄광고 전송관리', route: 'lineAdvrts.htm' },
                { label: '배너광고 전송관리', route: 'bannerAdvrts.htm' }
            ]
        }
    ],
    MENU: [
        {
            label: '주식신문',
            resource: false,
            icon: 'history_edu',
            open: true,
            path: '/newspaper',
            sub: [
                { label: '주요이슈', route: 'issue.htm' },
                { label: '주요 종목공시', route: 'maindiscl.htm' },
                // { label: '지수입력', route: 'index.htm' },
                { label: '신규추천', route: 'newrecommend.htm' },
                { label: '미국 특징주', route: 'usaNews.htm' },
                { label: '시간외 단일가 특이종목', route: 'unusual.htm' },
                { label: '미리보기', route: 'preview.htm', paper: true },
                { label: '미 등락률/테마 등락률/멘트', route: 'usrate.htm' },
                { label: '투데이월드', route: 'todayworld.htm' },
                { label: '비트코인/달러인덱스/VIX', route: 'indexPrice.htm' },
                { label: '조회수', route: 'viewCount.htm' },
                { label: '신문 상단표', route: 'closeIndex.htm' },
            ]
        },
        {
            label: '속보',
            resource: false,
            icon: 'send',
            open: true,
            path: '/newsflash',
            sub: [
                { label: '속보팝업', route: 'default.htm', popup: true },
                { label: '뉴스속보', route: 'news.htm' },
                { label: '속보예약', route: 'flashreserve.htm' },
                { label: '증시요약 미리보기', route: 'todaysummary.htm' },
                { label: '종목필터링', route: 'stockfiltering.htm' },
                { label: '속보문구관리', route: 'stockflashsetting.htm' }
            ]
        },
        {
            label: '특징 종목/테마',
            resource: true,
            icon: 'polymer',
            open: true,
            path: '/featured',
            sub: [
                { label: '특징주', route: 'stock.htm', resource: true },
                { label: '특징테마', name: '특징테마', value: 'FEATURED', route: 'theme.htm', resource: true },
                { label: '테마동향', route: 'trend.htm', resource: false },
                { label: '테마시황/데일리테마', route: 'daily.htm', resource: false },
                { label: '급등종목 - 네이버용', route: 'StockJump.htm', resource: false },
            ]
        },
        {
            label: '시황',
            value: 'Market',
            resource: true,
            icon: 'article',
            path: '/market',
            open: true,
            sub: [
                { label: '시황', route: 'domestic.htm' },
                { label: '미국테마시황', route: 'overseas.htm' },
            ]
        },
        {
            label: '종목',
            value: 'Stock',
            resource: true,
            icon: 'corporate_fare',
            path: '/stock',
            open: true,
            sub: [
                { label: '종목관리', route: 'manage.htm', resource: true },
                { label: '종목히스토리', route: 'history.htm', resource: true },
                { label: '부가설명', route: 'summaryextra.htm', resource: false },
                { label: 'SPOT입력', route: 'excelinput.htm', resource: false },
                { label: '강력매수', route: 'strongbuy.htm', resource: false },
                { label: '증권사 투자의견', route: 'investopinion.htm', resource: false },
                { label: '추정 컨센서스 입력', route: 'consensus.htm', resource: false },
                { label: '급등락사유분석', route: 'dividend.htm', resource: false },
            ]
        },
        {
            label: '테마',
            value: 'Theme',
            resource: true,
            icon: 'account_tree',
            path: '/theme',
            open: true,
            sub: [
                { label: '인포스탁테마', route: 'manage.htm', resource: false },
                { label: '테마종목', route: 'themestock.htm', name: '테마종목', value: 'THEME_STOCK', resource: false },
                { label: '테마히스토리', route: 'history.htm', resource: false },
                { label: '테마브리핑', route: 'themebriefing.htm' }
            ]
        },
        {
            label: '스케쥴',
            value: 'Event',
            resource: true,
            icon: 'calendar_today',
            path: '/event',
            open: true,
            sub: [
                { label: '스케쥴', route: 'manage.htm', resource: true },
                { label: '스케쥴 형식', route: 'scheduleForm.htm', resource: true },
                { label: '휴장일 관리', route: 'closed.htm' }
            ]
        },
        {
            label: '실적',
            value: 'Earning',
            resource: false,
            icon: 'assessment',
            path: '/earning',
            open: true,
            sub: [
                { label: '실적 일정입력', route: 'schedule.htm', resource: false },
                { label: '실적입력', route: 'earning.htm', resource: false },

                { label: '미리보기', route: 'preview.htm', resource: false },
                { label: '재무제표', route: 'financial.htm', resource: false },
                { label: '과거실적/재무조회', route: 'financialHistory.htm', resource: false },
            ]
        },
        {
            label: '마케팅',
            resource: false,
            icon: 'dashboard',
            open: true,
            path: '/marketing',
            sub: [
                { label: '광고', route: 'advertising.htm' },
                { label: '광고확인', route: 'advertisingCnfirm.htm' },
                // <!-- 20230920 광고매크로 -->
                // { label: "광고전송", route: "advertisingmacro.htm" },
            ]
        },
        {
            label: 'IR',
            resource: false,
            icon: 'dashboard',
            open: true,
            path: '/irnews',
            sub: [
                { label: 'IR 속보', route: 'flash.htm' },
                { label: 'IR/제휴언론사 기사관리', route: 'manage.htm' },
                { label: 'IR 인사이트', route: 'irinsight.htm' },
            ]
        },
        {
            label: '데이터',
            resource: false,
            icon: 'topic',
            open: true,
            path: '/data',
            sub: [
                { label: '통계', route: 'hitsMain.htm' },
                { label: '전자공시', route: 'discl.htm', resource: false },
                // { label: '과거 실적/재무 조회', route: 'advertising.htm' },
            ]
        },
        {
            label: '관리',
            resource: false,
            icon: 'manage',
            open: true,
            path: '/manage',
            sub: [
                { label: '공지사항', route: 'notice.htm' },
                { label: '사내게시판', route: 'inboard.htm' },
                { label: '업무 관리', route: 'workmanager.htm' },
                { label: '관리자 관리', route: 'staff.htm' },
                { label: '증권사 관리', route: 'stockcompany.htm' },
                { label: '회원관리', route: 'member.htm' },

            ]
        },
        {
            label: '전송관리 시스템',
            resource: false,
            icon: 'trnsmis',
            open: true,
            path: '/trnsmis',
            sub: [
                { label: '인포스탁 전송관리', route: 'infostock.htm' },
                { label: '인포스탁 재전송 ', route: 'resend.htm' },
                { label: '제휴언론사 전송관리', route: 'coprtnOxpr.htm' },
                { label: '광고고객사 전송관리', route: 'advrtsCtmmny.htm' },
                { label: '줄광고 전송관리', route: 'lineAdvrts.htm' },
                { label: '배너광고 전송관리', route: 'bannerAdvrts.htm' }
            ]
        }
    ],
    ROLE: {
        SU: 'superuser',
        PU: 'poweruser',
        OP: 'operator',
        EO: 'everyone',
        CU: 'customer'
    },
    TEAM: {
        HR: 'HR',
        MARKET: 'MARKET',
        MARKETING: 'MARKETING',
        IR: 'IR',
        IT: 'IT'
    },
    STATUS: {
        standby: 'standby',
        active: 'active',
        suspended: 'suspended',
        terminated: 'terminated'
    },
    STATUSLIST: [
        {
            id: 'active',
            desc: '활성'
        },
        {
            id: 'suspended',
            desc: '중지'
        },
        {
            id: 'terminated',
            desc: '해지'
        }
    ],
    MARKETNEWS_TYPE: [
        { label: '전체', value: -1 },
        { label: '개시', value: 1 },
        { label: '미개시', value: 0 }
    ],
    SCHEDULE_TYPE: [
        { label: '국내종목 - 실적 발표', value: 1 },
        { label: '국내종목 - 거래재개/정지', value: 2 },
        { label: '국내종목 - 추가 상장', value: 3 },
        { label: '국내종목 - 보호예수 해제', value: 4 },
        { label: '국내종목 - 신규상장/공모청약', value: 5 },
        { label: '국내종목 - 변경상장/재상장', value: 6 },
        { label: '국내종목 - 기타', value: 7 },
        { label: '국내지표', value: 8 },
        { label: '국내이슈', value: 9 },
        { label: '해외종목(실적 등)', value: 10 },
        { label: '해외지표', value: 11 },
        { label: '해외이슈', value: 12 },
        { label: '스포츠', value: 13 },
        { label: '기타(각종행사 등)', value: 14 },
        { label: '국내외 휴장', value: 15 },
    ],
    // 22.12.09 SUYE 스케줄 순서변경 기준: 카테고리
    SCHEDULE_INDEX: [
        { label: '국내종목 - 실적 발표', value: 5, type: 'EVENT_DOMESTIC_ANNOUNCE' },
        { label: '국내종목 - 거래재개/정지/상장폐지', value: 8, type: 'EVENT_DOMESTIC_RESUME_STOP' },
        { label: '국내종목 - 추가 상장', value: 9, type: 'EVENT_DOMESTIC_ADD_LISTING' },
        { label: '국내종목 - 보호예수 해제', value: 10, type: 'EVENT_DOMESTIC_LIFED' },
        { label: '국내종목 - 신규상장/공모청약', value: 6, type: 'EVENT_DOMESTIC_NEWLISTING_SUBSCRIPTION' },
        { label: '국내종목 - 변경상장/재상장', value: 7, type: 'EVENT_DOMESTIC_RE_CHANGE_LISTING' },
        { label: '국내종목 - 기타', value: 3, type: 'EVENT_DOMESTIC_ETC' },
        { label: '국내지표', value: 4, type: 'EVENT_DOMESTIC_INDICATOR' },
        { label: '국내이슈', value: 2, type: 'EVENT_DOMESTIC_ISSUE' },
        { label: '해외종목(실적 등)', value: 11, type: 'EVENT_OVERSEA_STOCKS' },
        { label: '해외지표', value: 12, type: 'EVENT_OVERSEA_INDICATOR' },
        { label: '해외이슈', value: 1, type: 'EVENT_OVERSEA_ISSUE' },
        { label: '스포츠', value: 13, type: 'EVENT_SPORTS' },
        { label: '국내외 휴장', value: 0, type: 'EVENT_CLOSED' },
    ],
    TRADINGMARKET_TYPE: [
        { label: '전체', value: null },
        { label: '코스피', value: 'Y' },
        { label: '코스닥', value: 'K' }
    ],
    // 회사 구분
    CORPCLS_TYPE: [
        { label: '코스피(유가)', value: 'Y' },
        { label: '코스닥', value: 'K' }
    ],
    NEWSFLASH_TYPE: [
        { label: '전체', value: 'FLASH_ALL' },
        { label: '수기', value: 'MARKET_NEWSFLASH_ADMIN' },
        { label: '수동', value: 'MARKET_NEWSFLASH_AUTO' },
        { label: '자동', value: 'MARKET_NEWSFLASH_MANUAL' },
        { label: '테마', value: 'MARKET_NEWSFLASH_THEME' },
        { label: '실패', value: 'MARKET_NEWSFLASH_FAIL' }
    ],
    NEWFLASH_NEWSTYPE1: [
        { label: '데일리리포트', value: 'MARKET_FLASH_DAILY_REPORT' },
        { label: '전일자 주요 지분변동 공시', value: 'MARKET_FLASH_DAILY_SHARE_CHANGE' },
        { label: '거래소/코스닥 스탁론 신규 매수 상위 종목 /하이스탁론', value: 'MARKET_FLASH_STOCKLOAN_SELL' },
        { label: '거래소/코스닥 스탁론 매도(상환) 상위 종목 /하이스탁론', value: 'MARKET_FLASH_STOCKLOAN_BUY' },
    ],
    NEWFLASH_NEWSTYPE2: [
        { label: '종목속보', value: 'MARKET_FLASH_STOCK' },
        { label: '환율속보', value: 'MARKET_FLASH_FOREX' },
        { label: '테마속보', value: 'MARKET_FLASH_THEME' }
    ],
    SEND_NEWFLASH_NEWSTYPE: [
        { label: '주요환율 및 반도체/상품동향', value: 'MARKET_FOREXRATE_TRAND' },
        { label: '전일 장마감 후 주요 종목 공시', value: 'MARKET_FLASH_STOCK_DISC_CLOSE' },
        { label: '장중 주요 종목 공시', value: 'MARKET_FLASH_STOCK_DISC_MIDDLE' }
    ],
    STOCK_FEATURED_TIME_TYPE: [
        { label: '전체', value: 'STOCK_FEATURED_ALL' },
        { label: '오전장', value: 'STOCK_AM_FEATURED', flash: 'AM' },
        { label: '오후장', value: 'STOCK_PM_FEATURED', flash: 'PM' }
    ],

    THEME_FEATURED_TIME_TYPE: [{ label: '전체', value: 'THEME_FEATURED_ALL' },
    { label: '오전장', value: 'THEME_FEATURED_AM' },
    { label: '오후장', value: 'THEME_FEATURED_PM' }],
    EXCLUSION_TYPE: [
        { label: '', value: 0 },
        { label: '제외', value: 1 },
        { label: '삭제', value: 'D' }
    ],
    MEMBER_TYPE: [
        { label: '준회원', value: 'ASSOCIATE' },
        { label: '정회원', value: 'REGULAR' },
        { label: '기업회원', value: 'CORPORATE' },
        { label: 'TEMPORARY', value: 'TEMPORARY' },
        { label: '탈퇴회원', value: 'WITHDRAWAL' }
    ],
    //회원구분
    /* 
        220420 meej 유료회원 1주일 추가
    */
    MEMBER_STATUS_TYPE: [
        { 'type': 'REGULAR_w1', 'typeNm': '유료회원 1주일', addDay: 7 },
        { 'type': 'REGULAR_m1', 'typeNm': '유료회원 1개월', addMonth: 1 },
        { 'type': 'REGULAR_m3', 'typeNm': '유료회원 3개월', addMonth: 3 },
        { 'type': 'REGULAR_m6', 'typeNm': '유료회원 6개월', addMonth: 6 },
        { 'type': 'REGULAR_y1', 'typeNm': '유료회원 12개월', addMonth: 12 },
        { 'type': 'ASSOCIATE_', 'typeNm': '준회원' },
        { 'type': 'ASSOCIATE_refund', 'typeNm': '환불회원' },
        { 'type': 'WITHDRAWAL_', 'typeNm': '탈퇴회원' }, // 221102 meej 탈퇴회원 추가
        { 'type': 'CORPORATE_', 'typeNm': '영업용' }, //기업회원
        { 'type': 'MARKETING_null', 'typeNm': '마케팅' },
        { 'type': 'SHINHAN_null', 'typeNm': '신한투자증권' },
        { 'type': 'ASSOCIATE_blacklist', 'typeNm': '블랙리스트' },
    ],
    //회원구분2
    /* 
        220420 meej 유료회원 1주일 추가
    */
    MEMBER_STATUS_TYPE2: {
        'REGULAR_w1': '유료회원 1주일',
        'REGULAR_m1': '유료회원 1개월',
        'REGULAR_m3': '유료회원 3개월',
        'REGULAR_m6': '유료회원 6개월',
        'REGULAR_y1': '유료회원 12개월',
        'ASSOCIATE_': '준회원',
        'ASSOCIATE_refund': '환불회원',
        'WITHDRAWAL_': '탈퇴회원',
        'CORPORATE_': '영업용',  //기업회원
        // 220831 meej 마케팅, 신한금융투자  추가
        'MARKETING_null': '마케팅',
        'SHINHAN_null': '신한투자증권',
        'ASSOCIATE_blacklist': '블랙리스트',
    },
    //회원 결제방법
    MEMBER_PAYMENT: {
        "CARD": "카드 결제",
        "MOBILE": "휴대폰 결제",
        "ACCOUNT": "ACCOUNT",
        "DEPOSIT": "무통장 입금"
    },
    //관리자 회원구분
    STAFF_ROLE: [
        { 'type': 'superuser', 'typeNm': '최고 관리자' },
        { 'type': 'poweruser', 'typeNm': '팀장' },
        { 'type': 'operator', 'typeNm': '직원' },
        { 'type': 'customer', 'typeNm': '외부(광고,언론)' },
        { 'type': 'everyone', 'typeNm': '미승인' },
    ],// team 
    STAFF_TEAM: [
        { 'type': 'HR', 'typeNm': '인사팀' },
        { 'type': 'MARKET', 'typeNm': '시황팀' },
        { 'type': 'MARKETING', 'typeNm': '마케팅팀(광고)' },
        { 'type': 'IR', 'typeNm': 'IR팀(언론)' },
        { 'type': 'IT', 'typeNm': 'IT팀' },
        { 'type': 'BLANK', 'typeNm': '미정' }
    ],
    //전송관리 인포스탁 B2B
    TRNSMISMANAGE_INFOSTOCK_B2B: [
        { 'code': 'MARKET_FLASH', 'value': '뉴스속보' },
        { 'code': 'STOCK_SUMMARY', 'value': '기업개요' },
        { 'code': 'CONSENSUS', 'value': '컨센서스' },
        { 'code': 'WORLD_INDEX', 'value': '월드인덱스' },
        { 'code': 'STOCK_HISTORY', 'value': '종목 히스토리' },
        { 'code': 'EVENT', 'value': '이슈&테마스케쥴' },
        { 'code': 'DAILY_THEME', 'value': '데일리테마' },
        { 'code': 'THEME_HISTORY', 'value': '테마 히스토리' },
        { 'code': 'THEME', 'value': '테마' },
        { 'code': 'THEME_STOCK', 'value': '테마기업개요' },
        { 'code': 'STOCK_HISTORY_NEW', 'value': '종목 히스토리(신규추천)' }
    ],
    TRNSMISMANAGE_INFOSTOCK_B2B2: {
        'MARKET_FLASH': '뉴스속보',
        'STOCK_SUMMARY': '기업개요',
        'CONSENSUS': '컨센서스',
        'WORLD_INDEX': '월드인덱스',
        'STOCK_HISTORY': '종목 히스토리',
        'EVENT': '이슈&테마스케쥴',
        'DAILY_THEME': '데일리테마',
        'THEME_HISTORY': '테마 히스토리',
        'THEME': '테마',
        'THEME_STOCK': '테마기업개요',
        'STOCK_HISTORY_NEW': '종목 히스토리(신규추천)'
    },
    //재무제표 재무제표구분
    FINANCIAL_FINANCIAL_GB: [
        { 'value': '1', 'name': '손익계산서' },
        { 'value': '2', 'name': '재무상태표' },
        { 'value': '3', 'name': '현금흐름표' }
    ],
    FINANCIAL_FINANCIAL_GB2: {
        '1': '손익계산서',
        '2': '재무상태표',
        '3': '현금흐름표'
    },
    //재무제표 연결/별도 구분
    COM_IFRS: [
        { 'value': 'CONSOLIDATED', 'name': '연결' },
        { 'value': 'SEPARATE', 'name': '별도' },
        { 'value': '', 'name': '전체' }
    ],
    COM_IFRS2: {
        'CONSOLIDATED': '연결',
        'SEPARATE': '별도',

    },
    //재무제표 연결/별도 구분
    FINANCIAL_REPORT_TYPE: [
        { 'value': '1', 'name': '1분기보고서' },
        { 'value': '2', 'name': '반기보고서' },
        { 'value': '3', 'name': '3분기보고서' },
        { 'value': '4', 'name': '사업보고서' }
    ],
    NEWSPAPER_TARGET: [
        { 'value': 'REGULAR', 'name': '홈페이지/유료회원' },
        { 'value': 'MARKETING', 'name': '마케팅' },
        { 'value': 'SHINHAN', 'name': '신한투자증권' },
    ],
    FINANCIAL_REPORT_TYPE2: {
        '1': '1분기보고서',
        '2': '반기보고서',
        '3': '3분기보고서',
        '4': '사업보고서'
    },
    //20250207 항목추가
    FINANCIAL_FIELDNAME: [
        { 'value': '매출액', 'name': '매출액' },
        { 'value': '영업이익', 'name': '영업이익' },
        { 'value': '순이익', 'name': '순이익' },
        { 'value': '지배기업의소유지분', 'name': '지배기업의소유지분' }
    ],
    //실적입력 구분
    EARNING_TYPE: [
        { 'value': 'EARNING', 'name': '잠정실적' },
        { 'value': 'CONSENSUS', 'name': '컨센서스' },
        { 'value': 'EARNING_Q_B', 'name': '잠정실적(분기초)' }
    ],
    EARNING_TYPE2: {
        'EARNING': '잠정실적',
        'CONSENSUS': '컨센서스',
        'EARNING_Q_B': '잠정실적(분기초)'
    },
    //분기
    COM_QUARTER: [
        { 'value': 'Q1', 'name': '1분기' },
        { 'value': 'Q2', 'name': '2분기' },
        { 'value': 'Q3', 'name': '3분기' },
        { 'value': 'Q4', 'name': '4분기' }
    ],
    COM_QUARTER2: {
        'Q1': '1분기',
        'Q2': '2분기',
        'Q3': '3분기',
        'Q4': '4분기'
    },
    //구분
    COM_STOCK_DIV: [
        { 'value': 'GENERAL', 'name': '일반기업' },
        { 'value': 'BANK', 'name': '은행' },
        { 'value': 'STOCK', 'name': '증권' },
        { 'value': 'INSURANCE', 'name': '보험' }
    ],
    COM_STOCK_DIV2: {
        'GENERAL': '일반기업',
        'BANK': '은행',
        'STOCK': '증권',
        'INSURANCE': '보험'
    },
    //단위
    COM_UNIT: [
        { 'value': 'WON', 'name': '원' },
        { 'value': 'THOUSAND', 'name': '천원' },
        { 'value': 'MILLION', 'name': '백만원' },
        { 'value': 'I00MILLION', 'name': '억원' },
        { 'value': 'BILLION', 'name': '십억원' },
    ],
    COM_UNIT2: {
        'WON': '원',
        'THOUSAND': '천원',
        'MILLION': '백만원',
        'I00MILLION': '억원',
        'BILLION': '십억원'
    },
    BOARD_TYPE: [
        { label: '공지사항', value: 'BOARD_NOTICE' },
        { label: '문의게시판', value: 'BOARD_ONE_ON_ONE' },
        { label: '무통장입금확인', value: 'BOARD_DEPOSIT' },
        { label: '스케줄형식', value: 'BOARD_SCHEDULE_FORM' },
        { label: '가짜뉴스신고센터', value: 'BOARD_FAKE_NEWS_CHECK' },
    ],

    DEPARTMENT_TYPE: [
        { 'value': 'IT지원', 'name': 'IT지원' },
        { 'value': '마케팅', 'name': '마케팅' },
        { 'value': '시황', 'name': '시황' },
        { 'value': '기업분석', 'name': '기업분석' },
        { 'value': '업무총괄', 'name': '업무총괄' },
        { 'value': '회계총괄', 'name': '회계총괄' },
        { 'value': 'IRP사업', 'name': 'IRP사업' },
    ],
    PERSON_NAME: [
        { 'value': '권용호', 'name': '권용호' },
        { 'value': '신민석', 'name': '신민석' },
        { 'value': '김병찬', 'name': '김병찬' },
        { 'value': '서동환', 'name': '서동환' },
        { 'value': '박광춘', 'name': '박광춘' },
        { 'value': '박정도', 'name': '박정도' },
        { 'value': '안호현', 'name': '안호현' },
        { 'value': '김민재', 'name': '김민재' },
        { 'value': '허준범', 'name': '허준범' },
        { 'value': '신민재', 'name': '신민재' },
        { 'value': '김연수', 'name': '김연수' },
        { 'value': '김윤기', 'name': '김윤기' },
        { 'value': '김민교', 'name': '김민교' },
        { 'value': '오승열', 'name': '오승열' },
        { 'value': '이재원', 'name': '이재원' },
        { 'value': '이연빈', 'name': '이연빈' },
        { 'value': '김현재', 'name': '김현재' },
    ],



    STATE: [
        { 'value': '예정', 'name': '예정' },
        { 'value': '진행중', 'name': '진행중' },
        { 'value': '완성', 'name': '완성' },
    ],
    NEWS_AD_TYPE: [
        { label: '주식신문 홍보', value: 'NEWS_AD_INFOSTOCK' },
        { label: '줄광고', value: 'NEWS_AD_LINE' },
    ],
    PAPER_TYPE: [
        {
            label: '조간신문',
            value: 'MARKET_MOBILENEWS_AM',
            subs: [
                { name: '국내외 주요 마감지수', key: 'PAPER_CLOSE_INDEX', value: '' },
                { name: '개장전 주요이슈 / 주요뉴스 클리핑', key: 'PAPER_ISSUE_CLIPPING', value: '' },
                { name: '미국증시 마감동향', key: 'PAPER_US_CLOSE_INDEX', value: '' },
                { name: '미국증시 마감동향', key: 'PAPER_US_CLOSE_NEWYORK', value: '' },
                { name: '미국증시 마감동향', key: 'PAPER_USA_SPFR', value: '' },
                { name: '이슈&테마스케쥴', key: 'PAPER_ISSTHEME', value: '' },
                { name: '증권사 신규추천', key: 'PAPER_RECOMMEND', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_KR_KOSPIKOSDAQ_TABLE', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_DOMESTIC_TREND', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_FUND_BALANCE', value: '' },
                // Fed 대차대조표 증감 표
                { name: '국내증시 마감동향', key: 'PAPER_KR_CLOSE_INDEX', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_THEME_FEATURED', value: '' },
                { name: '국내증시 마감동향', key: '"PAPER_THEME_CHANG_RATE_DOWN', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_THEME_CHANG_RATE_UP', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_STOCK_FEATURED_KOS_PI_DAQ', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_STOCK_FEATURED_KOS_PI_DAQ', value: '' },
                //특징 상한가 및 급등주 / 52주 신고가
                { name: '기술적분석 특징주', key: 'PAPER_KR_TECH', value: '' },
                //전일 장중 주요종목 공시
                //전일 장마감 주요종목 공시
                { name: '외국인,기관 순매수/순매도', key: 'PAPER_FOREIGN_INST_BUY_SELL', value: '' },
                { name: '투자주체별 매매동향', key: 'PAPER_DEMAND_MARKET', value: '' },
                { name: '프로그램 매매동향', key: 'PAPER_DEMAND_PROGRAM', value: '' },
                { name: '최근 15거래일 누적 공매도 순위', key: 'PAPER_ACCUM_SHORT', value: '' },
                { name: '전일 공매도 비중 상위종목', key: 'PAPER_KRX_SHORT', value: '' },
                { name: '시장수급관심주', key: 'PAPER_KOSPI_FOREIGN_CONTINUE_BUY', value: '' },
                { name: '시장수급관심주', key: 'PAPER_KOSPI_INST_CONTINUE_BUY', value: '' },
                { name: '시장수급관심주', key: 'PAPER_KOSDAQ_FOREIGN_CONTINUE_BUY', value: '' },
                { name: '시장수급관심주', key: 'PAPER_KOSDAQ_INST_CONTINUE_BUY', value: '' },
                { name: '시장수급관심주', key: 'PAPER_POPULAR', value: '' },
                { name: '시장수급관심주', key: 'PAPER_RUNOUT_RATE', value: '' },
                { name: '신용비율상위종목', key: 'PAPER_CREDIT_RATIO', value: '' },
                //하이스탁록 매수 / 상환
                //시간외 단일가 특이종목
                { name: '저 PER', key: 'PAPER_LOW_PER', value: '' }
            ]
        },
        {
            label: '중간신문',
            value: 'MARKET_MOBILENEWS_EM',
            subs:[
                {name:'국내외 주요 마감지수', key:'PAPER_CLOSE_INDEX', value:''},
                {name:'기술적분석 특징주', key:'PAPER_KR_TECH',value:''},
                //특징 종목 : 코스피
                //특징 종목 : 코스닥
            ]
        },
        {
            label: '석간신문',
            value: 'MARKET_MOBILENEWS_PM',
            subs: [
                { name: '국내외 주요 마감지수', key: 'PAPER_CLOSE_INDEX', value: '' },
                //장마감 주요이슈요약 / 주요뉴스 클리핑
                { name: '국내증시 마감동향', key: 'PAPER_KR_KOSPIKOSDAQ_TABLE', value: '' },
                //증시 주변 자금동향 / 국내펀드 자금 유출입 동향 / Fed 대차대조표 증감 표
                { name: '국내증시 마감동향', key: 'PAPER_KR_CLOSE_INDEX', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_THEME_FEATURED', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_THEME_CHANG_RATE_DOWN', value: '' },
                { name: '국내증시 마감동향', key: 'PAPER_THEME_CHANG_RATE_UP"', value: '' },
                //특징 종목 : 코스피
                //특징 종목 : 코스닥
                //특징 상한가 및 급등주 / 특징 하한가 및 급락주 / 52주 신고가
                { name: '기술적분석 특징주', key: 'PAPER_KR_TECH', value: '' },
                //장중 주요종목 공시
                //외국인,기관 순매수/순매도
                { name: '투자주체별 매매동향', key: 'PAPER_DEMAND_MARKET', value: '' },
                { name: '시장 수급 관심주(외국인 소진율 증가 종목, 인기검색종목)', key: 'PAPER_POPULAR', value: '' },
                { name: '시장 수급 관심주(외국인 소진율 증가 종목, 인기검색종목)', key: 'PAPER_RUNOUT_RATE', value: '' },
                //1억원 이상 매수체결 상위 20종목
                //하이스탁록 매수 / 상환
                //아시아증시 마감시황(일본, 중국)
                //시간외 단일가 특이종목
                //이슈&테마 스케줄
                //저 PBR
            ]
        },
    ],

})
