<template>
    <section class="row">
        <div>
            <p style="font-size: small; font-weight: bold; text-align: center;">상단표 멘트삽입</p>

            <p class="changeRow2"><q-checkbox label="조간상단표 멘트삽입" v-model="mentCheckAm" size="xs" /><i-btn class="q-ml-md" label="수정" @click="onClick3('am')"/></p> 
            <textarea
                v-model="mentTextAm"
                cols="80"
                rows="2"
            ></textarea>          
            <hr class="hrStyle">
            <p class="changeRow2"><q-checkbox label="석간상단표 멘트삽입" v-model="mentCheckPm" size="xs" /><i-btn class="q-ml-md" label="수정" @click="onClick3('pm')"/></p>         
            <textarea
                v-model="mentTextPm"
                style="float:inline-end;"
                cols="80"
                rows="2"
            ></textarea> 
            <hr class="hrStyle">
            <p class="changeRow2"><q-checkbox label="중간상단표 멘트삽입" v-model="mentCheckEm" size="xs" /><i-btn class="q-ml-md" label="수정" @click="onClick3('em')"/></p>         
            <textarea
                v-model="mentTextEm"
                style="float:inline-end;"
                cols="80"
                rows="2"
            ></textarea>       
        </div>
    </section>
</template>
<script>
import Button from '@/components/common/Button.vue'

import {  mapState, mapActions } from 'vuex'
//market_news
export default {
    name: 'Comment',
    components: {
        'i-btn' : Button,
    },
    data () {
        return {
            mentCheckAm: false,
            mentCheckPm: false,
            mentCheckEm: false,
            mentTextAm:'',
            mentTextPm:'',
            mentTextEm:''
        }
    },
    created(){
        this.GET_SETTINGS()
        .then((res)=> {
            this.mentTextAm = (res===undefined? '': res.MENTAM);
            this.mentTextPm = (res===undefined? '': res.MENTPM);
            this.mentTextEm = (res===undefined? '': res.MENTEM);
            this.mentCheckAm = (res===undefined? false: res.MENTCHECKAM=='0'?false:true);
            this.mentCheckPm = (res===undefined? false: res.MENTCHECKPM=='0'?false:true);
            this.mentCheckEm = (res===undefined? false: res.MENTCHECKEM=='0'?false:true);
        })

    },
    computed : {
        ...mapState ({
            settings: state => state.resource.settings
        }),

    },
    methods: {
        ...mapActions( 'alert', ['showError', 'showMessage']),
        ...mapActions( 'resource', ['SET_SETTINGS', 'GET_SETTINGS']),

        onClick3(apm) {
            console.log(apm)
            let params;
            if(apm==='am'){
                params = {
                    items:[{
                        MENTAM:this.mentTextAm.replace('\n','<br>')                    
                    },{
                        MENTCHECKAM:this.mentCheckAm==true?'1':'0'
                    }
                    ]
                }
            }
            else if(apm==='pm'){
                params = {
                    items:[{
                        MENTPM:this.mentTextPm.replace('\n','<br>')                    
                    },{
                        MENTCHECKPM:this.mentCheckPm==true?'1':'0'
                    }
                    ]
                }                
            }
            else {
                params = {
                    items:[{
                        MENTEM:this.mentTextEm.replace('\n','<br>')                    
                    },{
                        MENTCHECKEM:this.mentCheckEm==true?'1':'0'
                    }
                    ]
                }                
            }
            this.SET_SETTINGS(params)
            .then(()=>{
                this.showMessage({vc:this, message:'업데이트 완료'})
            })
            .catch((err) => {
                this.showError({ vc:this, error:err})
            })
        },            
    },
    watch: {
        settings(val, old) {
            if(val !== old) {
                this.usRate = (this.settings===undefined? '': this.settings.US_RATE);
            }
        }
    }

}
</script>
