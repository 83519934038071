<template>
    <article>
        <div class="flex justify-end q-pa-xs q-gutter-sm">
            <!-- 220831 meej 자동공시 수정 버튼 추가 -->
            <span v-if="showEditBtn" style="padding-top:7px" > 
                {{FormatDate(checkDate)}} {{newsType.value === 'MARKET_STOCK_DISC_MIDDLE' ? "장중" :"장마감"}} 
            </span>
            <i-btn v-if="showEditBtn" label="자동공시 수정" @click="editAutoDiscl()" />
            <i-btn label="취소" @click="goListPage()" />
            <i-btn label="자동공시 정렬" @click="sortAutoDiscl()" />
        </div>
        <q-scroll-area visible class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section
                    v-if="isDetailPage || isEditPage"
                    class="q-gutter-sm"
                >
                    <i-field
                        label="작성일자"
                        :text="FormatDate(item.createTime)"
                    />
                </q-card-section>
                <q-card-section v-if="isDetailPage">
                    <i-input
                        v-model="item.content"
                        :rows="rows"
                        type="textarea"
                        
                    />
                    
                </q-card-section>
                <q-card-section v-else>
                    <i-input
                        v-model="item.content"
                        type="textarea"
                        :rows="rows"
                        :label="label"
                        @focus="goEditPage()"
                    />
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <!-- 하단 버튼 -->
        <!-- 220527 meej 주요종목공시 버튼 label 변경 -->
        <div :class="unusual ? 'row reverse' : ''" class="q-mx-md">
            <i-btn
                v-if="isListPage || isRegisterPage"
                class="full-width"
                :class="unusual ? 'q-pa-xs' : 'full-width'"
                :label="unusual ? '코멘트 등록' : disclosure? '홈페이지 입력 / 스팟 입력':'확인'"
                @click="write()"
            />
            
            <!-- 220331 meej if조건문 수정 : isDetailPage -> isEditPage -->
            <div v-else-if="isDetailPage || isEditPage" class="full-width">
                <i-btn class="full-width" :label="disclosure? '홈페이지만 수정':'수정'" @click="write()" />
            </div>
            
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import { FormatDate, today, isEmpty, clone, nowTime, addWorkDay } from '@/util'

import Button from '@/components/common/Button'
import Field from '@/components/common/Field'
import Input from '@/components/common/Input'


import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    components: {
        //'i-datetime': DateTime,
        'i-field': Field,
        'i-btn': Button,
        'i-input': Input,
        
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
        label: {
            type: String,
            default: '내용',
        },
        unusual: {
            type: Boolean,
            default: false,
        },
        /* 
            220527 meej 주요종목공시일때 버튼 label 변경 추가
        */
        disclosure: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            item: {
                title: '',
                content: '',
            },
            rows:19,
            infobotId : null, // 인포봇으로 입력된 데이터 id
            showEditBtn : false,
            checkDate : "",
        }
    },
    created() {
        // EventBus를 통한 리소스 추가하기
        this.$parent.$on('sendResource', this.addResource)
        this.initItem()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            selectItem: (state) => state.market_news.item,
            
        }),

        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isRegisterPage',
            'isFlashPage',
            'isEditPage',
        ]),
        ...mapGetters('flash', ['isSuccess']),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('newspaper', ['SET_ISSUE_ITEMS', 'SET_ITEM']),
        ...mapMutations('market_news', ['INIT_LIST']),
        ...mapMutations('resource', ['REQUEST_RESOURCE']),
        ...mapMutations({
            SET_STATUS: 'flash/SET_STATUS',
        }),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('newspaper', ['WRITE_ISSUE']),
        ...mapActions('newspaper', ['GET_DISCL_LIST']),
        ...mapActions('market_news', ['ASYNC_LIST', 'WRITE_ITEM', 'GET_INIT_CONTENT', 'EDIT_AUTO_DISCL']),
        

        initItem() {
            Debug.log('initItem')
            this.item.title = ''
            this.item.content = ''
            // 220712 meej 초기 입력시 showDate = null 
            this.item.showDate = null
            this.SET_ITEM(null)
            
            this.selectedStocks = []
            this.infobotId = null
        },
        
        // 2025.03.
        sortAutoDiscl() {
            if (isEmpty(this.item.content)) {
                this.showMessage({ vc: this, message: "정렬할 공시 내용이 없습니다." })
                return
            }

            const lines = this.item.content.trim().split(/\r?\n/)
            const grouped = {}
            let currentKey = null

            lines.forEach(line => {
                const headerMatch = line.match(/^\*\s*([^(]+)\((\d+)\)\s*-\s*(.*)?/)
                const onlyHeaderMatch = line.match(/^\*\s*([^(]+)\((\d+)\)\s*-\s*$/)

                if (headerMatch) {
                    const stockName = headerMatch[1].trim()
                    const stockCode = headerMatch[2].trim()
                    currentKey = `${stockName}(${stockCode})`
                    const contentText = (headerMatch[3] || '').trim()

                    if (!grouped[currentKey]) {
                        grouped[currentKey] = []
                    }

                    if (contentText) {
                        grouped[currentKey].push(contentText)
                    }
                } else if (onlyHeaderMatch) {
                    const stockName = onlyHeaderMatch[1].trim()
                    const stockCode = onlyHeaderMatch[2].trim()
                    currentKey = `${stockName}(${stockCode})`

                    if (!grouped[currentKey]) {
                        grouped[currentKey] = []
                    }
                } else if (currentKey && line.trim() !== '') {
                    grouped[currentKey].push(line.trim())
                }
            })

            const result = Object.entries(grouped).map(([key, contents]) => {
                if (contents.length === 1) {
                    return `* ${key} - ${contents[0]}` // 한 건이면 그냥 출력
                } else {
                    const joined = contents.map((c, idx) => {
                        const trimmed = c.trim()
                        // 마지막 항목은 마침표 제거
                        if (idx === contents.length - 1) return trimmed
                        return trimmed.endsWith('.') ? trimmed : trimmed + '.'
                    }).join('  ')
                    return `* ${key} - ${joined}`
                }
            }).join('\n\n') + '\n\r'

            this.item.content = result
        },
        getDisclosure() {
            var params = {
                newsType: this.newsType.value,
            }

            this.GET_DISCL_LIST(params)
                .then((data) => {
                    for (let i = 0; i < data.items.length; i++) {
                        let news = data.items[i]
                        if(news.include !== undefined && news.include === '1') continue
                        this.item.content +=
                            '* ' +
                            news.stockName +
                            '(' +
                            news.stockCode +
                            ')  ' +
                            news.content +
                            '\n\n'
                    }
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
            this.requestResource()
        },
        requestResource() {
            Debug.log('requestResource :', this.newsType.value)
            let req = {}
            if (this.newsType.value === 'MARKET_STOCK_DISC_MIDDLE' ||
                this.newsType.value === 'MARKET_STOCK_DISC_CLOSED'
            ) {
                req.menu = 'Stock'
                req.newsType = { name: '공시', value: 'STOCK_DISCLOSURE' }
                req.date = today()
            } else {
                req.menu = 'Market'
                req.newsType = this.newsType
                req.date = today()
            }
            this.$nextTick(function() {
                this.REQUEST_RESOURCE(req)
            })
        },

        goEditPage() {
            if (this.isListPage) {
                this.setPageStatus(this.PAGE_STATUS.REGISTER)
                if (
                    this.newsType.value === 'MARKET_STOCK_DISC_MIDDLE' ||
                    this.newsType.value === 'MARKET_STOCK_DISC_CLOSED'
                ) {
                    this.getDisclosure()
                    // 220829 meej 주요 종목 공시 _ 인포봇으로 입력된 데이터 가져옴
                    this.getInitContent()
                    this.showEditBtn = true
                } else {
                    this.$nextTick(function() {
                        this.requestResource()
                    })
                }
            } else if (this.isDetailPage) {
                this.setPageStatus(this.PAGE_STATUS.EDIT)
                this.requestResource()
            } 
        },
        goListPage() {
            Debug.log('goListPage')
            this.$nextTick(function() {
                this.initItem()
            })
            this.setPageStatus(this.PAGE_STATUS.LIST)
            this.showEditBtn = false
        },
        // goIssueConfirm() {
        //     this.setPageStatus(this.PAGE_STATUS.CONFIRM)
        // },
        goDetailPage() {
            this.setPageStatus(this.PAGE_STATUS.DETAIL)
        },
        
        addResource(res) {
            Debug.log('addResource res = ', res)
            let content = (isEmpty(this.item.content)?'':this.item.content)

            if(res.menu === 'Stock') {

                for(let i = 0; i < res.items.length; i++) {
                    let news = res.items[i]
                    content += '* '+news.stockName+'('+news.stockCode+') - '+ news.content+'\n\n'
                }
                Debug.log('addResource 2 = ', content)
            } else {
                for(let i = 0; i < res.items.length; i++) {
                    content += res.items[i].content+'\n'
                }
            }
            this.$nextTick(function () {
                    this.item.content = content
                })

         },
        // 목록 조회하기
        getList() {
            let param = {
                newsType: this.newsType.value,
            }
            this.ASYNC_LIST(param)
        },
        // 등록하기
        write() {
            Debug.log('write newstype = ', this.newsType)
            // 사용안함 : newspaper/issue/Content.vue 로 이동
            // if (
            //     this.newsType.value === 'MARKET_BEFORE_ISSUE' ||
            //     this.newsType.value === 'MARKET_AFTER_ISSUE'
            // ) {
            //     if (isEmpty(this.item.content)) {
            //         this.showMessage({
            //             vc: this,
            //             message: '내용을 입력해주세요.',
            //         })
            //         return
            //     }
            //     let mesg = this.isEditPage
            //         ? '수정하시겠습니까?'
            //         : '등록하시겠습니까?'
            //     this.showAlert({ vc: this, message: mesg }).then(() => {
            //         this.doWriteIssue()
            //     })
            // } else 
            if (this.newsType.value === 'MARKET_STOCK_DISC_MIDDLE' ||
                this.newsType.value === 'MARKET_STOCK_DISC_CLOSED'
            ) {
                // 속보 입력 쪽
                if (isEmpty(this.item.content)) {
                    this.showMessage({
                        vc: this,
                        message: '내용을 입력해주세요.',
                    })
                    return
                }
                let mesg = this.isDetailPage
                    ? '수정하시겠습니까?'
                    : '등록하시겠습니까?'
                this.showAlert({ vc: this, message: mesg }).then(() => {
                    this.doWrite()
                })
            } else if (this.newsType.value === 'MARKET_OVERTIME_COMMENT') {
                if (isEmpty(this.item.content)) {
                    this.showMessage({
                        vc: this,
                        message: '내용을 입력해주세요.',
                    })
                    return
                }
                let mesg = this.isEditPage
                    ? '수정하시겠습니까?'
                    : '등록하시겠습니까?'
                this.showAlert({ vc: this, message: mesg }).then(() => {
                    this.doWrite()
                })
            } else {
                // 지수 입력
            }
        },
        // 사용안함 : newspaper/issue/Content.vue 로 이동
        // doWriteIssue() {
        //     let params = {
        //         title: this.newsType.name,
        //         content: this.item.content,
        //     }
        //     if (this.isRegisterPage) {
        //         params.newsType = this.newsType.value
        //     } else {
        //         params.id = this.item.id
        //         params.newsType = this.item.newsType1
        //     }
        //     this.WRITE_ISSUE(params)
        //         .then((res) => {
        //             this.showMessage({
        //                 vc: this,
        //                 message: this.isEditPage
        //                     ? '수정되었습니다.'
        //                     : '등록되었습니다.',
        //             })
        //             // let temp = this.handleIssue(res.content)
        //             // temp.forEach((row) => {
        //             //     Debug.log(row)
        //             // })
        //             this.INIT_LIST()
        //             this.getList()
        //             this.SET_ITEM(res)
        //             this.goIssueConfirm()
        //         })
        //         .catch((err) => {
        //             this.showError({ vc: this, error: err })
        //         })
        // },
        // maketnew 에 저장후 newsflash 호출로 변경 
        // doWriteFlash() {
        //     let params = {
        //         title: this.newsType.name,
        //         content: this.item.content,
        //     }
        //     if (this.isRegisterPage) {
        //         params.newsType = this.newsType.value
        //     } else {
        //         params.id = this.item.id
        //         params.newsType = this.item.newsType1
        //     }
        //     this.WRITE_FLASH(params)
        //         .then(() => {
        //             this.showMessage({
        //                 vc: this,
        //                 message: this.isEditPage
        //                     ? '수정되었습니다.'
        //                     : '등록되었습니다.',
        //             })
        //             this.INIT_LIST()
        //             this.getList()
        //             this.goListPage()
        //         })
        //         .catch((err) => {
        //             var error = err
        //             this.showError({ vc: this, error: error })
        //         })
        // },
        doWrite() {
            // 2021.11.01 suki --> write  하면 바로 홈페이지 게시되게 수정(for 주요종목공시)
            let params = {
                title: this.newsType.name,
                content: this.item.content,
                // 220712 meej 수정시 showDate today로 입력되는 오류 수정
                // showDate: today()
                showDate: this.item.showDate != null ? this.item.showDate : today()
            }
            if (this.isRegisterPage) {
                params.newsType = this.newsType.value

                // 220829 meej 주요 종목 공시 _ 인포봇으로 입력된 데이터 id 전달
                if ( this.infobotId != null && 
                    ( this.newsType.value === 'MARKET_STOCK_DISC_MIDDLE' 
                        || this.newsType.value === 'MARKET_STOCK_DISC_CLOSED' )
                ) {
                    params.infobotId = this.infobotId
                }   
            } else {
                params.id = this.item.id
                params.newsType = this.item.newsType1
            }
            this.WRITE_ITEM(params)
                .then(() => {
                    this.showMessage({
                        vc: this,
                        message: this.isDetailPage
                            ? '수정되었습니다.'
                            : '등록되었습니다.',
                    })
                    this.INIT_LIST()
                    this.getList()
                    this.goListPage()
                    // 220829 meej 초기화
                    this.infobotId = null
                })
                .catch(() => {
                    /* 
                        220513 meej 없는 종목코드일경우, 종목명과 종목코드 다를경우 에러 처리로 인한 메시지 수정
                    */
                    var err = "등록에 실패하였습니다. 내용을 다시 확인해주세요."
                    this.showError({ vc: this, error: err })
                })
        },
        doUpdateWeb(flag) {
            let params = {
                title: this.newsType.name,
                content: this.item.content,
                id: this.item.id,
                newsType: this.item.newsType1
            }
            if (flag) {
                params.showDate = today()
            } else {
                params.showDate = ''
            }
            this.WRITE_ITEM(params)
                .then(() => {
                    // this.showMessage({
                    //     vc: this,
                    //     message: flag
                    //         ? '홈페이지에 게시 되었습니다.'
                    //         : '홈페이지에 게시가 취소 되었습니다.'
                    // })
                    // this.INIT_LIST()
                    // this.getList()
                    // this.goListPage()
                })
                .catch((err) => {
                    this.showError({ vc: this, error: err })
                })
        },
        FormatDate(datetime) {
            return datetime ? FormatDate(datetime) : null
        },
        // 220829 meej 주요 종목 공시 _ 인포봇으로 입력된 데이터 가져옴
        // 220918 meej 주요 종목 공시 날짜 표시 추가
        getInitContent(){
            this.initItem()
            this.GET_INIT_CONTENT({
                newsType : this.newsType.value
            }).then((res)=>{
                if(res.item != null){
                    this.item.content = res.item.content
                    this.infobotId = res.item.id
                    this.checkDate = res.item.createTime
                    // this.showEditBtn = true
                } else {
                    // this.showEditBtn = false
                    if(nowTime('A') === 'AM' && this.newsType.value === 'MARKET_STOCK_DISC_CLOSED') {
                        this.checkDate = addWorkDay(-1,'YYYYMMDD')
                    } else {
                        this.checkDate = today('YYYYMMDD')
                    }
                }
                
            }).catch(()=>{
                // this.showEditBtn = false
            })
        },
        // 220831 meej 자동공시 수정
        // 220919 meej 자동공시 신규 등록 추가
        editAutoDiscl() {
            // if(isEmpty(this.infobotId)||isEmpty(this.newsType.value)||isEmpty(this.item.content)){
            if(isEmpty(this.newsType.value)||isEmpty(this.item.content)){
                this.showError({ vc: this, error: "수정할 내용이 없습니다." })
                return
            }
            
            // 220905 meej 수정후 새로 입력(인포봇)될때 2칸 생기는 오류 수정
            this.item.content = this.item.content.trimEnd()+"\n\r"

            // 내용만 변경함
            this.showAlert({ vc: this, message: "자동공시 내용을 수정하시겠습니까?" })
            .then(() => {
                let param = {
                    id : this.infobotId,
                    newsType: this.newsType.value,
                    content : this.item.content,
                    showDate : isEmpty(this.infobotId) ? this.checkDate : null
                }
                this.EDIT_AUTO_DISCL(param)
                .then(()=>{
                    this.showMessage({ vc: this, message: '수정되었습니다.'})
                    this.getInitContent()
                }).catch((err) => {
                    this.showError({ vc: this, error: err })
                })
            })
        }
    },
    watch: {
        pageStatus(val) {
            Debug.log('watch pageStatus val=', val)
            if (val === this.PAGE_STATUS.DETAIL) {
                this.rows = 25
                // this.$nextTick(function() {
                //     this.item = clone(this.selectItem)
                //     Debug.log('watch pageStatus this.item=', this.item)
                // })
            } else {
                this.rows = 19
            }
        },
        newsType(val, old) {
            Debug.log('watch newsType val=', val, ', old = ', old)
            if (val != old) {
                this.initItem()
                //this.getList()

                // 220829 meej 주요 종목 공시 _ 인포봇으로 입력된 데이터 가져옴
                if (this.isRegisterPage && (
                        this.newsType.value === 'MARKET_STOCK_DISC_MIDDLE' ||
                        this.newsType.value === 'MARKET_STOCK_DISC_CLOSED'
                    )
                ) {
                    this.getInitContent()
                }
            }
        },
        selectItem(val, old) {
            Debug.log('watch newsTyselectItempe val=', val.id, ', old = ', (old ? old.id:'null'))
            if (val != old) {
                this.item = clone(val)
                
                //this.getList()
            }
        }
    },
    beforeDestroy() {
        if (this.windowRef) this.windowRef = null
        //this.$store.dispatch('flash/ASYNC_NEWSTYPE', 'NewsFlashAdminType')
    },
}
</script>
